<template>
  <div class="banner"></div>
  <div class="back-home">
    <van-icon color="#fff" size="28" name="arrow-left" @click="handleGoBack"/>
    <div class="title">营养报告</div>
  </div>
  <div class="business-reports">
    <div class="report-date" @click="handleDataClick">
      <div class="date-des">{{ formData.date }}</div>
      <van-icon color="#000000" size="22" name="tosend" />
    </div>

    <div class="report-content">
      <div class="report-total-container">
        <div class="report-total" @click="handleYongClick">
          <span class="title-label">用餐次数</span>
          <span class="title-value">{{ state.eatCount ?? 0 }}</span>
        </div>
        <div class="report-total" @click="handleChuClick">
          <span class="title-label">出入书院</span>
          <span class="title-value" style="color: #91cc75">{{
            state.comeCount ?? 0
          }}</span>
        </div>
      </div>
    </div>
    <div class="report-chart">
      <div class="report-title">餐食种类</div>
      
      <Charts ref="reportChart" v-if="state.reportData" :data="state.reportData"/>
      <EmptyData style="margin-top: 100px" v-if="!state.reportData"/>
    </div>
    <div class="report-table">
      <div class="report-title">营养达标分析</div>
      <SortPaginationTable
        style="margin-top: 10px"
        :data="state.tableList"
        :columns="columns"
      />
    </div>
    <van-calendar
      :min-date="state.minDate"
      :max-date="state.maxDate"
      v-model:show="state.calendarShow"
      @confirm="onConfirm"
    />
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { showLoadingToast, Calendar } from "vant";
import Charts from "@/components/charts.vue";

import { postDayDishDris } from "@/api/report";
import SortPaginationTable from "@/components/business/SortPaginationTable.vue";
import dayjs from "dayjs";
import EmptyData from '@/components/EmptyData.vue';

const columns = [
  {
    title: "营养指标",
    dataIndex: "name",
    width: 120,
    tag: true,
    start: true,
  },
  {
    title: "指标值",
    dataIndex: "target",
    width: 80,
  },
  {
    title: "参考值",
    dataIndex: "init",
    width: 80,
  },
  {
    title: "对比",
    dataIndex: "point",
    width: 80,
  },
];
const colors = [
  "#EE6666",
  "#FAC858",
  "#91CC75",
  "#FFC19F",
  "#CC9EFF",
  "#73C0DE",
  "#3098D8",
  "#5470C6",
  "#86A9BE",
  "#6B94AD",
  "#17435E",
];

export default defineComponent({
  components: {
    Charts,
    SortPaginationTable,
    Calendar,
    EmptyData
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const reportChart = ref(null)

    const state = reactive({
      reportData: null,
      tableList: [],
      comeCount: 0,
      eatCount: 0,
      foodNum: 0,
      calendarShow: false,
      maxDate: new Date(dayjs().format("YYYY-MM-DD")),
      minDate: new Date(dayjs().subtract(1, "year").format("YYYY-MM-DD")),
    });

    const formData = reactive({
      date: dayjs().format("YYYY-MM-DD"),
    });

    onMounted(async () => {
      queryDayDishDris();
    });

    const handleYongClick = () => {
        uni.navigateTo({
          url: "/pages/food/history-list/history-list",
        });
    }

    const handleChuClick = () => {
        uni.navigateTo({
          url: "/pages/college/enterRecords/enterRecords",
        });
    }

    const handleGoBack = () => {
        uni.navigateBack();
    }

    const queryDayDishDris = async () => {
      try {
        const result = await postDayDishDris(
          {
            queryTime: formData.date,
            userId: route?.query?.userId,
          },
          route?.query?.token
        );

        console.log("result====", result);
        if (result.code == "00") {
          state.foodNum = result.data.foodNum;
          state.reportData = result.data.foodTypes;
          reportChart.value && reportChart.value.resizeChart()
          state.tableList = result.data.jsonArray.map((item, index) => {
            return {
              ...item,
              tagValue: colors[index],
            };
          });
          state.comeCount = result.data.comeCount;
          state.eatCount = result.data.eatCount;
          
        } else {
          state.reportData = null;
          state.tableList = 0;
          state.comeCount = 0;
          state.eatCount = 0;
          state.foodNum = 0;
        }
      } catch (error) {}
    };

    const onConfirm = (option) => {
      formData.date = dayjs(option).format("YYYY-MM-DD");
      state.calendarShow = false;
      queryDayDishDris();
    };

    const handleDataClick = () => {
      console.log("点击选择时间");
      state.calendarShow = true;
    };

    return {
      state,
      columns,
      onConfirm,
      formData,
      handleDataClick,
      handleGoBack,
      handleYongClick,
      handleChuClick,
      reportChart
    };
  },
});
</script>

<style scoped>
.banner {
  width: 100%;
  height: 180px;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  background-image: url("../assets/banner.png");
  background-repeat: repeat;
  z-index: 99;
}

.title {
  color: #fff;
  font-size: 18px;
  text-align: center;
  width: calc(100% - 70px);
  font-weight: 600;
}

.back-home {
  z-index: 100;
  margin-top: 44px;
  position: relative;
  text-align: left;
  margin-left: 22px;
  display: flex;
}
.business-reports {
  background: #e9f1f8;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
  padding-bottom: 22px;
  z-index: 100;
  margin-top: 14px;
  position: relative;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.report-total-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.report-total {
  display: flex;
  flex-direction: column;
}

.title-label {
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 17px;
  color: #000000;
}

.title-value {
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 20px;
  color: #fac858;
  margin-top: 12px;
}

.report-content {
  margin: 0 10px;
  padding-top: 14px;
  width: calc(100% - 20px);
  background-color: #fff;
  margin: 0 10px;
  border-radius: 7px;
  display: flex;
  height: 124px;
}
.report-chart {
  height: 380px;
  width: calc(100% - 20px);
  background-color: #fff;
  margin: 0 10px;
  border-radius: 7px;
  margin-top: 12px;
}

.report-title {
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 17px;
  color: #000000;
  text-align: left;
  padding-left: 10px;
  padding-top: 10px;
}

.report-table {
  width: calc(100% - 20px);
  background-color: #fff;
  margin: 0 10px;
  border-radius: 7px;
  margin-top: 12px;
}

.report-date {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 12px;
  padding-top: 12px;
  margin-right: 20px;
}

.date-des {
  margin-right: 12px;
  font-size: 17px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
}

:deep(.van-calendar__selected-day) {
    background: #063051
}
:deep(.van-calendar__confirm) {
    background: #063051;
    height: 44px;
}
</style>
