<template>
  <div class="page-container">
    <div class="content-list">
      <div
        class="list-item"
        v-for="(item, index) in state.finishList"
        :key="index"
      >
        <div class="item-first">
          <div class="item-title">
            {{ item.type }}
          </div>
          <div class="item-time">
            {{ item.eatTime }}
          </div>
        </div>

        <div
          class="item-content"
          v-for="(h, j) in item.eatFoodsList"
          :key="j"
          @click="handleFoodsClick(h)"
        >
          <van-image
            width="66px"
            height="66px"
            :src="getImg(h.imgUrl)"
          ></van-image>
          <div class="content-text">
            <div class="content-name">
              {{ h.foodName }}
            </div>
            <div class="content-number">
              {{ h.foodCount ? "×" + h.foodCount : "" }}
            </div>
          </div>
        </div>
      </div>
      <EmptyDataCard
        style="margin-top: 88px"
        v-show="!state.finishList.length"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import EmptyDataCard from "@/components/EmptyDataCard.vue";
import { querySyDayDishGetEatRecords } from '@/api/user'
import { useRoute } from "vue-router";

export default defineComponent({
  name: "MealRecord",

  components: {
    EmptyDataCard,
  },

  setup() {
    const route = useRoute();
    const state = reactive({
      finishList: [],
    });
    onMounted(() => {
        querySyDayDishGetEatRecordsData()
    });

    const querySyDayDishGetEatRecordsData = async () => {
      try {
        const result = await querySyDayDishGetEatRecords({
            userId: route?.query?.userId
        });
        console.log("result:", result);
        if (result.code === "00") {
          state.finishList = result.data ?? []
        } else {
          state.finishList = [];
        }
      } catch (error) {
        console.log("error:>>>", error);
        state.finishList = [];
      }
    }

    function getImg(img) {
      return process.env.VUE_APP_BASE_API + "/file/" + img;
    }

    return {
      state,
      getImg,
    };
  },
});
</script>

<style lang="scss" scoped>
.page-container {
  background: #e9f1f8;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;

  .content-list {
    // padding-bottom: 22px;
    padding: 6px;
    .list-item {
      .item-first {
        display: flex;
        justify-content: space-between;
        height: 43px;
        align-items: center;
        padding-left: 15px;
      }

      .item-content {
        display: flex;
        padding: 8px 15px;
        background-color: #fff;
      }

      .content-text {
        margin-left: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
}
</style>