<template>
  <div class="we-chat-mini">
    <div class="swipe-content">
      <van-swipe
        class="my-swipe"
        :autoplay="3000"
        indicator-color="white"
        style="height: 200px"
        lazy-render
      >
        <van-swipe-item v-for="image in images" :key="image">
          <van-image fit="cover" :src="image" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="report-content">
      <div class="report-total-container">
        <div class="report-total" @click="handleYongClick(ModuleType.College)">
          <span class="title-label">书院学生</span>
          <span v-if="state.isLogin" class="title-value">{{
            state.overview.stuNo ?? 0
          }}</span>
          <span v-else class="title-value">--</span>
        </div>
        <!-- <div
          class="report-total"
          @click="handleYongClick(ModuleType.InCourtyard)"
        >
          <span class="title-label">在院学生</span>
          <span
            v-if="state.isLogin"
            class="title-value"
            style="color: #91cc75"
            >{{ state.comeCount ?? 0 }}</span
          >
          <span v-else class="title-value" style="color: #91cc75">--</span>
        </div> -->

        <div class="report-total" @click="handleYongClick(ModuleType.Due)">
          <span class="title-label">即将到期</span>
          <span v-if="state.isLogin" class="title-value" style="color: red">{{
            state.overview.stuEndNo ?? 0
          }}</span>
          <span v-else class="title-value" style="color: red">--</span>
        </div>
      </div>
    </div>

    <div v-for="(item, index) in state.statistics" :key="index">
      <div style="text-align: left; margin-left: 12px; margin-top: 12px">
        {{ item.time }}
      </div>
      <div class="report-content">
        <div class="report-total-container">
          <div
            class="report-total"
            @click="handleChuClick(ModeType.ReturnHospital)"
          >
            <span v-if="state.isLogin" class="title-value"
              >{{ item.actualArrival ?? 0 }}/{{
                item.expectedArrival ?? 0
              }}</span
            >
            <span v-else class="title-value">--</span>
            <span class="title-label title-label-bottom">回院</span>
          </div>
          <div class="report-total" @click="handleChuClick(ModeType.Lunch)">
            <span v-if="state.isLogin" class="title-value"
              >{{ item.actualLunch ?? 0 }}/{{ item.expectedLunch ?? 0 }}</span
            >
            <span v-else class="title-value">--</span>
            <span class="title-label title-label-bottom">午餐</span>
          </div>

          <div class="report-total" @click="handleChuClick(ModeType.NoonBreak)">
            <span v-if="state.isLogin" class="title-value"
              >{{ item.actualNap ?? 0 }}/{{ item.expectedNap ?? 0 }}</span
            >
            <span v-else class="title-value">--</span>
            <span class="title-label title-label-bottom">午休</span>
          </div>

          <div
            class="report-total"
            @click="handleChuClick(ModeType.LeavingHospital)"
          >
            <span v-if="state.isLogin" class="title-value"
              >{{ item.actualDeparture ?? 0 }}/{{
                item.expectedDeparture ?? 0
              }}</span
            >
            <span v-else class="title-value">--</span>
            <span class="title-label title-label-bottom">离院</span>
          </div>
        </div>
      </div>
    </div>
    <div class="login-container" v-if="state.isLogin">
        <!-- <van-cell title="退出登录" @click="handleGoLogin" is-link /> -->
        <van-button size="small" @click="handleGoLoginOut" plain type="primary" style="width: 120px">退出登录</van-button>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";

import EmptyData from "@/components/EmptyData.vue";
import HomeBackImg from "@/assets/home_back.jpg";
import HomeBackImg_1 from "@/assets/home_back_1.jpg";
import HomeBackImg_2 from "@/assets/home_back_2.jpg";

import { ModuleType, ModeType } from "@/utils/const";

import { queryGetDataForNoon, queryGetDataSummary, logOutSystem } from "@/api/user";
import dayjs from "dayjs";
import { showConfirmDialog, showNotify } from "vant";

export default defineComponent({
  components: {
    EmptyData,
  },
  setup() {
    const images = [HomeBackImg_1, HomeBackImg, HomeBackImg_2];
    const router = useRouter();
    const route = useRoute();

    const state = reactive({
      isLogin: false,
      statistics: [
        {
          actualArrival: null,
          actualDeparture: null,
          actualLunch: null,
          actualNap: null,
          expectedArrival: null,
          expectedDeparture: null,
          expectedLunch: null,
          expectedNap: null,
          time: "中午段",
        },
      ],
      overview: {},
    });

    onMounted(async () => {
      console.log("pathParams:", route?.query);
      if (route?.query?.page) {
        router.push({
          path: `${route?.query?.page}`,
          query: {
            id: route?.query?.id,
          },
        });
      }
      queryGetDataForNoonData();
      queryGetDataSummaryData();
    });

    const queryGetDataSummaryData = async () => {
      if (!localStorage.getItem("token")) {
        state.isLogin = false;
        return;
      }
      try {
        const result = await queryGetDataSummary({});
        console.log("result:", result);
        if (result.code === "00") {
          state.overview = result.data ?? {};
          state.isLogin = true;
        } else if (result.code === "02") {
          state.isLogin = false;
        } else {
        }
      } catch (error) {
        console.log("error:>>>", error);
      }
    };

    const queryGetDataForNoonData = async () => {
      if (!localStorage.getItem("token")) {
        state.isLogin = false;
        return;
      }
      try {
        const result = await queryGetDataForNoon({
          day: dayjs().format("YYYY-MM-DD"),
        });
        console.log("result:", result);
        if (result.code === "00") {
          state.statistics = result.data ?? [
            {
              actualArrival: null,
              actualDeparture: null,
              actualLunch: null,
              actualNap: null,
              expectedArrival: null,
              expectedDeparture: null,
              expectedLunch: null,
              expectedNap: null,
              time: "中午段",
            },
          ];
          state.isLogin = true;
        } else if (result.code === "02") {
          state.isLogin = false;
        } else {
        }
      } catch (error) {
        console.log("error:>>>", error);
      }
    };

    const handleGoLogin = () => {
      router.push({
        path: "/login",
      });
    };

    const handleGoLoginOut = () => {
      showConfirmDialog({
        title: "温馨提示",
        message: "您确定要退出登录吗？",
      })
        .then(async () => {
          // on confirm
          const _user = localStorage.getItem("userInfo");
          const userInfo = JSON.parse(_user ?? "{}");
          const result = await logOutSystem({
            id: userInfo.id
          })
          if(result.code === '00') {
            localStorage.removeItem("userInfo");
            localStorage.removeItem("token")
            showNotify({ type: 'success', message: '退出登录成功' , duration: 2000 })
            state.isLogin = false;
          } else {
            showNotify({ type: 'warning', message: result.message, duration: 2000 })
          }
          
        })
        .catch((e) => {
          // on cancel
          console.log("取消登录",e);
        });
    }

    const handleYongClick = (moduleType) => {
      if (!isLoginDialog()) {
        return;
      }
      router.push({
        path: "/collegeStudents",
        query: {
          moduleType,
        },
      });
    };

    const handleChuClick = (moduleType) => {
      if (!isLoginDialog()) {
        return;
      }
      router.push({
        path: "/monitorStudents",
        query: {
          moduleType,
        },
      });
    };

    const isLoginDialog = () => {
      if (state.isLogin) {
        return true;
      }
      showConfirmDialog({
        title: "温馨提示",
        message: "当前操作需要登录，请前往登录。",
      })
        .then(() => {
          // on confirm
          console.log("确认登录");
          handleGoLogin();
        })
        .catch(() => {
          // on cancel
          console.log("取消登录");
        });
    };

    return {
      state,
      handleGoLogin,
      images,
      handleYongClick,
      ModuleType,
      handleChuClick,
      ModeType,
      handleGoLoginOut
    };
  },
});
</script>

<style scoped>
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 200px;
  text-align: center;
}

.we-chat-mini {
  background: #e9f1f8;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
  padding-bottom: 22px;
  z-index: 100;
  margin-top: 4px;
  position: relative;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.swipe-content {
  margin-left: 6px;
  margin-right: 6px;
}

.report-content {
  margin: 12px 6px;
  padding-top: 14px;
  width: calc(100% - 12px);
  background-color: #fff;
  border-radius: 7px;
  display: flex;
  height: 124px;
}

.report-total-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.report-total {
  display: flex;
  flex-direction: column;
}

.title-label {
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 17px;
  color: #000000;
}

.title-value {
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 20px;
  color: #fac858;
  margin-top: 12px;
}

.title-label-bottom {
  margin-top: 12px;
}

.login-container {
  text-align: center;
  position: absolute;
  width: 100vw;
  bottom: 40px;
}
</style>
