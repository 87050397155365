<template>
  <div class="page-container">
    <div class="content-item" v-for="(item, index) in state.list" :key="index">
      <div class="item-header-info">
        <div class="item-header-info-name">{{item.faceTypeDesc || '--'}}</div>
        <div class="item-header-info-status-value">
          {{item.faceTime || '--'}}
        </div>
      </div>
    </div>
    <EmptyData style="margin-top: 100px" v-if="!state.list.length"/>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { querySyFaceLogSelectPage } from '@/api/user'
import { useRoute } from "vue-router";
import EmptyData from '@/components/EmptyData.vue';

export default defineComponent({
  name: "CollegeStudents",

  components: {
    EmptyData
  },

  setup() {
    const route = useRoute();
    const state = reactive({
      list: []
    })
    onMounted(() => {
      querySyFaceLogSelectPageData()
    });

    const querySyFaceLogSelectPageData = async () => {
      try {
        const result = await querySyFaceLogSelectPage({
            page:{
                pageIndex: 1,
                pageSize: 100
            },
            personId: route?.query?.userId
        });
        console.log("result:", result);
        if (result.code === "00") {
          state.list = result.data?.list ?? []
        } else {
          state.list = [];
        }
      } catch (error) {
        console.log("error:>>>", error);
        state.list = [];
      }
    }

    return {
      state
    };
  },
});
</script>

<style lang="scss" scoped>
.page-container {
  background: #e9f1f8;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
  margin-bottom: 24px;
}

.content-item {
  background: #fff;
  margin: 12px 8px;
  border-radius: 4px;
  padding: 12px;
  text-align: left;
}

.item-header-info {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
  align-items: center;

  .item-des {
    color: #333333;
    font-size: 14px;
    vertical-align: middle;
  }

  .item-header-info-name {
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    font-weight: 600;

    span {
      font-size: 16px;
      font-weight: 500;
      color: burlywood;
    }
  }

  .item-header-info-status {
    color: #fff;
    font-size: 14px;
    background: #113050;
    padding: 4px 12px;
    border-radius: 24px;
  }

  &-text {
    color: #113050;
    font-size: 12px;
    padding: 4px 12px;
  }

  &-value {
    font-size: 12px;
    padding: 4px 12px;
  }

  .item-header-info-other {
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    display: flex;
    align-items: center;

    p {
      color: #ff3638;
    }
  }

  
}
</style>