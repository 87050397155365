import request from '../utils/request'

export function loginSystem(params) {
  return request({
    url: '/syUser/loginEmp',
    method: 'post',
    data:params
  })
}


export function logOutSystem(params) {
  return request({
    url: '/syUser/logOut',
    method: 'post',
    data:params
  })
}


export function queryGetDataForNoon(params) {
  return request({
    url: '/stuEmp/getDataForNoon',
    method: 'post',
    data: params
  })
}

export function queryLogInSendCode(params) {
  return request({
    url: '/syUser/logInSendCode',
    method: 'post',
    data: params
  })
}

export function queryGetDataSummary(params) {
  return request({
    url: '/stuEmp/getDataSummary',
    method: 'post',
    data: params
  })
}

export function queryGetSignStu(params) {
  return request({
    url: '/stuEmp/getSignStu',
    method: 'post',
    data: params
  })
}

export function queryGetDataForNoonDetail(params) {
  return request({
    url: '/stuEmp/getDataForNoonDetail',
    method: 'post',
    data: params
  })
}

export function querySyFaceLogSelectPage(params) {
  return request({
    url: '/syFaceLog/selectPage',
    method: 'post',
    data: params
  })
}

export function querySyDayDishGetEatRecords(params) {
  return request({
    url: '/syDayDish/getEatRecords',
    method: 'post',
    params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

export function queryBusinessUserSelectPage(params) {
  return request({
    url: '/businessUser/selectPage',
    method: 'post',
    data: params
  })
}

export function querySyPerformanceDetail(params) {
  return request({
    url: '/syPerformanceDetail/detail',
    method: 'post',
    data: params
  })
}

export function queryGetSignStuDetail(params) {
  return request({
    url: '/stuEmp/getSignStuDetail',
    method: 'post',
    data: params
  })
}

export function querySyPerformanceUpdate(params) {
  return request({
    url: '/syPerformanceDetail/update',
    method: 'post',
    data: params
  })
}


export function querySyPerformanceAdd(params) {
  return request({
    url: '/syPerformanceDetail/add',
    method: 'post',
    data: params
  })
}

export function querySyUserUpdateFace(params) {
  return request({
    url: '/syUser/updateFace',
    method: 'post',
    data: params
  })
}







