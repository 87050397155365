import { createRouter, createWebHashHistory } from 'vue-router'
import Informations from '../views/Informations.vue'
import WeChatMini from '../views/weChatMini/index.vue'
import Login from '../views/weChatMini/login.vue'
import WeeklyRecipe from '../views/WeeklyRecipe.vue'
import BusinessReports from '../views/BusinessReports.vue'


import CollegeStudents from '../views/weChatMini/collegeStudents.vue'
import StudentsInfo from '../views/weChatMini/studentsInfo.vue'
import SignIn from '../views/weChatMini/signIn.vue'
import TravelRecords from '../views/weChatMini/travelRecords.vue'
import MealRecord from '../views/weChatMini/mealRecord.vue'
import SignInRecord from '../views/weChatMini/signInRecord.vue'
import MonitorStudents from '../views/weChatMini/monitorStudents.vue'

const routes = [
  {
    path: '/informations',
    component: Informations,
    name: 'Informations',
    meta: { title: '资讯' }
  },
  {
    path: '/weeklyRecipe',
    component: WeeklyRecipe,
    name: 'WeeklyRecipe',
    meta: { title: '每周食谱' }
  },
  {
    path: '/userAgreement',
    component: () => import(/* webpackChunkName: "userAgreement" */ '../views/UserAgreement.vue'),
    name: 'UserAgreement',
    meta: { title: '用户协议' }
  },
  {
    path: '/privacyAgreement',
    component: () => import(/* webpackChunkName: "privacyAgreement" */ '../views/PrivacyAgreement.vue'),
    name: 'PrivacyAgreement',
    meta: { title: '用户协议' }
  },
  {
    path: '/businessReports',
    component: BusinessReports,
    name: 'BusinessReports',
    meta: { title: '营养报告' }
  },
  {
    path: '/weChatMini',
    component: WeChatMini,
    name: 'WeChatMini',
    meta: { title: '优益优加' }
  },
  {
    path: '/login',
    component: Login,
    name: 'Login',
    meta: { title: '优益优加登录' }
  },
  {
    path: '/collegeStudents',
    component: CollegeStudents,
    name: 'CollegeStudents',
    meta: { title: '书院学生' }
  },
  {
    path: '/studentsInfo',
    component: StudentsInfo,
    name: 'StudentsInfo',
    meta: { title: '学生详情' }
  },
  {
    path: '/signIn',
    component: SignIn,
    name: 'SignIn',
    meta: { title: '签到统计' }
  },
  {
    path: '/travelRecords',
    component: TravelRecords,
    name: 'TravelRecords',
    meta: { title: '进房情况' }
  },
  {
    path: '/mealRecord',
    component: MealRecord,
    name: 'MealRecord',
    meta: { title: '餐食记录' }
  },
  {
    path: '/signInRecord',
    component: SignInRecord,
    name: 'SignInRecord',
    meta: { title: '签到记录' }
    
  },
  {
    path: '/monitorStudents',
    component: MonitorStudents,
    name: 'MonitorStudents',
    meta: { title: '监控' }
    
  }
  

]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
